@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Kode+Mono&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kode Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Example of using the imported font for specific elements */
h1, h2, h3, .cursive-text {
  font-family: 'Dancing Script', cursive;
}

canvas {
  user-select: none;
}

.text-above-canvas {
  position: relative;
  z-index: 10; /* Ensure this is higher than the canvas' z-index */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto; /* Add scroll if content overflows */
}

.text-above-canvas details {
  width: 90%; /* Limit width to prevent full-screen expansion */
  max-width: 600px; /* Adjust this value based on your design needs */
  margin: 0 auto; /* Center the details elements */
}

.text-above-canvas summary {
  cursor: pointer;
  font-size: 1.5em;
}

@media (max-width: 600px) {
  .text-above-canvas summary {
    font-size: 1.2em; /* Smaller font size for screens up to the width of an iPhone SE */
  }
}

.text-above-canvas p {
  font-size: 1em;
}

details[open] summary ~ * {
  animation: slideDown 0.5s ease-in-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

details summary {
  cursor: pointer;
}

details summary::-webkit-details-marker {
  display: none;
}

.title {
  font-size: 10em;
}

@media (max-width: 600px) {
  .title {
    font-size: 8em; /* Smaller font size for screens up to the width of an iPhone SE */
  }
}

.subtitle {
  font-size: 1.5em;
}

@media (max-width: 600px) {
  .subtitle {
    font-size: 1.2em; /* Smaller font size for screens up to the width of an iPhone SE */
  }
}